.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  background-color: #132e47;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #6df0c9;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* stolen from https://codepen.io/chrisharper22/pen/abvBRBr */
.white-button,
.blue-button {
  display: inline-block;
  font-family: Whitney, "Open Sans", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 11pt;
  border-radius: 3px;
  cursor: pointer;
  height: 45px;
  width: 250px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.blue-button {
  background-color: #7289da;
  border: 2px solid #7289da;
  color: #fff;
}

.white-button {
  background-color: #fff;
  border: 2px solid #fff;
  color: #000;
}